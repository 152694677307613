@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,400i,500,500i,600,600i,700,700i,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
.systematrix-layout
{
  font-family: 'Raleway' !important;
  color: #211D12;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*** Systematrix custom styles **/
.main-menu .navbar
{
   background-color:rgba(0,0,0, 0.3);
}
.main-menu .nav-link
{
   color: #ffffff;
   font-size:18px;
   margin: 0 10px;
   font-weight: 300;
}
.logo-sys
{
   width: 35%;
}

.main-slider .slider-img
{
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: cover;
} 
.footer-section
{
   /* background: url('./images/footer-bg.png') no-repeat center center;
   background-size: cover;   */
   padding: 30px 40px 10px 40px;
   color: #fff;
   background-color: #4f4f4f;
}
.footer-section .nav-link
{
  color: #fff;
}
.footer-logo
{
    width: 45%;
    margin-left:15%;
}
.systematrix-layout
{
     overflow-x: hidden;
}


.systematrix-layout .carousel-caption
{
    top:35%;
}
.carousel-caption h3
{
    font-size:45px;
}
.carousel-caption p
{
   font-size:45px;
}
.head-black
{
    font-size:24px;
    line-height: 40px;
    
    
}
.systematrix-layout .carousel-indicators li
{
   height: 12px;
   border-radius: 30px !important;
   width:12px;
}

/* .overview-content-section
{
    margin: 0 120px;
} */
.head-blue
{
  color:#2F80ED;
  font-size:35px;
    line-height: 1.8;
    padding: 40px 80px;
}

.m-20
{
    margin:0 10%;
    padding: 40px 0;
}
.border-purple
{
   border:4px solid #7B54E2;
   border-radius: 20px;
}
.fit-img img{
   max-width: 100%;
   max-height: 100%;
   display: block;
} 
.flagship-section
{
   background-color: #F1F2F3;
   background: url('./images/background_element.png') no-repeat center center;
  background-size: cover; 
  margin-top:-120px;  
}
.main-menu .nav-link.active
{
   font-weight: 600;
}
/* .flagship-section::before
{
  content:'';
  width: 0;
  height: 0;
  border-bottom: 100px solid #f1f2f3;
  border-left: 100px solid transparent;
  position: relative;
  margin-top:-100px;
} */
.p-80
{
   padding: 200px 0 200px 0;
}

.getstarted-section
{
  background: url('./images/uielement.png') no-repeat center center;
  /* background-size: cover;   */
  background-size: 100% 100%;
  
}
.overview-content-section .getstarted-section::before
{
  content:'';
  width: 0;
  height: 0;
  border-bottom: 100px solid #f1f2f3;
  border-left: 100px solid transparent;
  position: relative;
  margin-top:-100px;
} 
.over-view::before
{
  content:'';
  width: 0;
  height: 0;
  border-bottom: 120px solid #f1f2f3;
  border-left: 120px solid transparent;
  position: relative;
  margin-top: -20px;
  margin-left: 93%;
  
}

.head
{
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
}
.product-info .head
{
  margin-bottom: 10px;
  padding-bottom: 0;
}
.product-info .m-20, .productsuite-section .m-20
{
  padding-top:20px;
}
.p20
{
    padding: 130px 0 0 0;
}

.btn-pad
{
    padding:20px 60px;
}

.fpara
{
    font-size: 18px;
    font-weight: 300;
    padding: 0 32px 0 0px;
    line-height: 40px;
    text-align: left;
}
.fline
{
   border-top:1px solid #ffffff !important;
}

.footer-heading::after
{
  content: "";
  display: block;
  width: 85%;
  border-bottom: 1px solid #ffffff;
  top: 10px;
  position: relative;
}
.address-info p
{
  font-size:16px;
 font-family: 'Open Sans', sans-serif;
}
.fpara, .fbullet
{
  margin-top:20px;
}
.address-info
{
  margin-top:30px;
}
.copyright-info
{
  position: relative;
 
  font-size: 10px;
}
.copyright-info p
{
   float: left;
   margin-left:20px;
}
.career-page .page-header-img
{
  background: url('./images/careersvisual.png') no-repeat center center;
  background-size: cover;  
  height: 700px;
}
.contact-page .page-header-img
{
  background: url('./images/3.png') no-repeat center center;
  background-size: cover;  
  height: 700px;
}
.product-page .page-header-img
{
  background: url('./images/products-visual.png') no-repeat center center;
  background-size: cover;  
  height: 700px;
}
.about-page .page-header-img
{
  background: url('./images/Aboutus_visual.png') no-repeat center center;
  background-size: cover;  
  height: 700px;
}
.testimonial-page .page-header-img
{
  background: url('./images/testimonialsvisual.png') no-repeat center center;
  background-size: cover;  
  height: 700px; 

} 
.usecase-page .page-header-img
{
  background: url('./images/usecase.png') no-repeat center center;
  background-size: cover;  
  height: 700px;
}

.career-page .container-fluid, .contact-page .container-fluid, .page-top-header .page-header-img .container-fluid
{
    padding: 0;
    margin: 0;
}
.about-page  .page-header-img .container-fluid
{
  padding: 0;
  margin: 0;
}
/* .career-page .page-header-content
{
       top:50%;
       position :absolute;
       color:#ffffff;
       left: 20%;
} */
/* .contact-page .page-header-content
{
  top:50%;
  position :absolute;
  color:#ffffff;
  left: 45%;
} */

.career-navtabs .nav-tabs
{
  border-bottom: none !important;

}
.career-navtabs .nav-tabs 
{
  padding: 30px 20px 60px 20px;
}
.career-navtabs .nav-tabs .nav-item
{
     width: 50%;
     padding-left: 15px;
     padding-right: 15px;
}
.career-navtabs .nav-tabs .nav-link
{
  border: 1px solid #B1CFF8 !important;
   color: #111111;
   border-radius: 10px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    position: relative;
}

/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
  border:none !important;
} */
.career-navtabs .nav-tabs .nav-link.active
{
  background-color: #2F80ED;
  color: #fff;  
  /* border-bottom: 10px solid #00C9F7 !important; 
  border-radius: 20px; */
}
.career-navtabs .nav-tabs .nav-link.active::before
{
  content: "";
  display: block;
  width: 100%;
  border-bottom: 10px solid #00C9F7;
  top: 75px;
  position: relative;
  
  border-radius: 15px;
}
.career-navtabs .nav-tabs .nav-item .nav-link.active::after {
  content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 20px solid #00C9F7;
    left:45%;
    bottom: -26%;
    z-index: 9998;
}
.career-navtabs .tab-content
{
   padding-top: 40px;
}
.career-navtabs .card-body.active
{
   background-color: #00C9F7;
   border-radius: 15px;
   border:none;
   color:#fff;
   
}
.career-navtabs .card-body
{
   background-color: #F1F2F3;
   border-radius: 15px;
   border:none;
   color:#000000;
   margin-bottom: 40px;
   
}
.career-navtabs .card-title
{  
  
  font-size:30px;
  font-weight: 500;
}
.career-navtabs .card-text
{
  font-size:20px;
}
.career-navtabs .card-body .btn-link
{
   padding: 0 !important; 
   color: #4F93ED;
}
.career-navtabs .card-body .btn-link:hover
{
  border-color: #F1F2F3;
}
.career-navtabs .card-body .btn-link img
{
    margin-left:10px;
}
.job-title-head
{
    color: #2F80ED;
    font-size: 40px;
}

.job-des 
{
  font-weight: 500;
  padding-top: 20px;
}
.job-des h5
{
   font-size: 25px;
   padding-bottom: 15px;
   padding-top: 15px;

}
/* .job-des .jobdes-list
{
   list-style:none;
} */
.job-des .jobdes-list li
{
    
     padding: 2px 0;
     font-size: 18px;
     line-height: 40px;
     margin-top: 5px;
     position: relative;
     margin-left: -25px;
}
.btm-text
{
    font-size: 24px;
    line-height: 45px;
}
.mail-info
{
  color: #2F80ED;
  border-bottom:1px solid #2F80ED;
  font-size: 30px;
  font-weight: 600;
}
.readmore-link
{
   padding: 0; 
   color:#5F9CEE;
   font-weight: 500;
   cursor: pointer;
}
.info.btm-text
{
     padding:40px 0 50px 0;
     line-height: 45px;
}
.job-details
{
   padding-top:60px;
   padding-bottom: 50px;
}
.job-des h3
{
    padding:15px 0;
}
.map-style
{ 
    border:0;
    width: 100%;
    height: 740px;
}
.address p
{
    font-size:20px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    line-height: 15px;
    margin-top:20px;
}

.text-orange
{
  color:#E9720D;
  margin-bottom: 40px;
}
.text-field
{
    border:1px solid #83CBCB;
    border-radius: 8px;
    height: 55px;
    margin-bottom: 25px;

}

.text-field.custom-text-area
{
  height: 100px !important;
}

.contact-form
{
   padding: 20px 0;
   margin-bottom: 40px;
}
.contact-form .text-orange
{
   font-size:40px;
}
.mt-60
{
   margin-top:60px;
}
.back-btn-text
{
  margin-left: 35px;
  border-bottom: 1px solid #000;
  font-size:18px;
  color: #000;

}
.back-btn
{
    padding-top:20px
}
.arrow-slider
{
   margin-top:40px;
}
.mt-5
{
   margin-top:15px !important;
}

.page-header-content h2
{
  font-size: 45px;
}

.tab-1 .MuiTab-wrapper::before
{
    
    content: url('./images/Product-tabicon1.png');
}
.tab-2 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon2.png');
}
.tab-3 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon3.png');
}
.tab-4 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon4.png');
}
.tab-5 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon5.png');
}
.tab-6 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon6.png');
}
.tab-7 .MuiTab-wrapper::before
{
  content: url('./images/Product-tabicon7.png');
}
.systematrix-layout .MuiTab-textColorPrimary
{
   border:1px solid #AFCD82;
   border-radius: 15px;
   color: #000;
   font-size: 25px;
   margin: 0 30px;
   width: 220px;
    padding: 30px 0;
}
.systematrix-layout .MuiAppBar-colorDefault
{
    background: none;
}
.systematrix-layout .MuiPaper-elevation4
{
  box-shadow: none;
}
.systematrix-layout .MuiTab-textColorPrimary:focus
{
  outline: none !important;
}
.systematrix-layout .PrivateTabIndicator-colorPrimary-3
{
  background-color: #ffffff !important;
}
.systematrix-layout .MuiTab-textColorPrimary.Mui-selected
{
    background-color: #AFCD82;
    color: #000;
    font-weight: 700;
}
.systematrix-layout .MuiSvgIcon-fontSizeSmall {
  font-size: 60px;
  fill: #AFCD82 !important;
}
.systematrix-layout .MuiTab-root
{
  font-family: 'Raleway' !important;

}
.systematrix-layout .MuiTouchRipple-root
{
  display: none;
}

.hide {
  display:none
}

.product-tab-head h3
{
  color: #29A6A7;
  font-size: 32px;
  font-weight: 600;
}

.border-green
{
  border:4px solid #29A6A7;
   border-radius: 20px;
}

.product-tab-img
{
     padding: 10px;
     margin: 20px 0;
    
}

.clearfix
{
   clear: both;
}
.pb-10
{
  padding-bottom: 70px;
}
.product-tab-head
{
    padding: 10px 50px;
}
.systematrix-layout .MuiTab-root
{
  text-transform: none !important;
}

.about
{
    width: 75%;
}
.abt-para
{
   font-size: 20px;
   float: left;
   line-height: 40px;
   margin-left:18%;
  
}

.mtb-10
{
   margin: 0;
}
.abt-iluus
{
  margin-bottom: -43px;
  margin-left: 116px;
}
.rectangle-box
{
   background:url('./images/aboutus_rectangle.png') no-repeat center center;
   background-size: cover;  
}
.mp-30
{
   padding: 40px;
}
.mt-20
{  
    margin-top:80px;

}
.rectangle-text
{
    color: #fff;
   padding: 0px 70px;
    line-height: 40px;
    font-size: 24px;
    text-align:center;
    margin: 0 3px;

}
.abt-list
{
  margin:0 8%;
}
.abt-list li {
  list-style-type: none;
  color: #000;
  font-size: 20px;
    font-weight: normal;
    line-height: 43px;
  margin-top: 15px;
  position: relative;
  padding-left: 60px;
  line-height: 40px;
}
.abt-list li::before {
  content: "\f111";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #3CADE4; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  font-family: 'FontAwesome';
  font-size: 15px;
  position: absolute;
    font-family: 'fontawesome';
    top: 0;
    left: 0;
}
 
.testimonial-content-section .rectangle-box
{
  background:url('./images/Rectangle-blue.png') no-repeat;
  background-size: 100% 100%;
  margin-bottom: 50px;
  margin-top:0;
  padding-top: 40px;
}
/* .testimonial-content-section .getstarted-section
{
  background: url('./images/testimonial-rectangle.png') no-repeat center center;
  background-size: cover;  
} */
/* .testimonial-content-section .bg-gray
{
  background-color: #eee;
} */
 .testimonials-text .card-text
{
   font-size: 20px;
   font-weight: 600;
   line-height: 40px;
   padding: 25px 30px 10px 30px;
   text-align: left;
   font-style: italic;
  margin-bottom: 0;
}

.testimonials-text .card-body
{
    padding: 0;
} 
.testimonials-text .card-body::before
{
   content: url('./images/comment_collan.svg');
}
.mtop
{
   margin-top:60px;
}
.page-top-header .page-header-content
{
    position: relative;
    color:#fff;
    
}
.page-top-header .page-header-content .content-headtext
{
  padding-top: 20%;
}
.scrol-tabs
{
   margin-left:50px;
}
.usecase-content-section .nav-pills .nav-link.active, .usecase-content-section .nav-pills .show>.nav-link
{
   background-color: #2F80ED;
   color: #fff !important;
   border-right: 10px solid #00C9F7 !important;
}

.usecase-content-section .nav-pills .nav-link
{
     padding: 30px !important;
     border: 1px solid #2F80ED !important;
     font-size: 20px;
    text-align: right;
}
.usecase-content-section .nav-pills .nav-item
{
  margin:5px 0;
}
.usecase-content-section .nav-tabs
{  
  border-bottom: none;
}
.usecase-content-section .nav-pills .nav-item .nav-link.active::after{
	left: 95%;
	
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #00C9F7;
  border-width: 15px;
  margin-top: -15px;
}
.light-green
{
  color: #03AB97;
  font-weight: 500;
  font-size:35px;
  line-height: 55px;
}
.orange
{
    color: #E9720D;
    font-weight: 500;
    font-size: 35px;
    
}
.usecase-tabcontent
{
   margin-left: 35px;
}
.usecase-textslider  .tab-pane
{
  margin-top: 32px;
}
.orange span{
  color: #03AB97;
}
.list-checkmark
{
   list-style: none;
   text-align: left;
}
.list-checkmark li
{

        list-style-type: none;
        color: #211D12;
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        margin-top: 15px;
        position: relative;
        padding-left: 25px;
    }
    
    
  
.list-checkmark li::before
{
    content: url(./images/check-mark-button.svg);
    position: absolute;
    top: 6px;
    left: -21px;
  
}
.usecase-content-section .rectangle-box
{
  background:url('./images/Rectangle200.png') no-repeat;
  background-size: 100% 100%;

}
.rectangle-box
{
   margin-top:30px;
}
.list-circle
{
  list-style: none;
  margin-top:10%;
  margin-right: 10px;
}
.list-circle li
{
    font-size: 25px;
    color: #fff;
    line-height: 50px;
    font-weight: 500;
    margin-top: 15px;
    position: relative;
    padding-left: 25px;
}
.list-circle li::before {
  content: "\f111";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #fff; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
 
  font-family: 'FontAwesome';
  font-size: 15px;
  position: absolute;
    font-family: 'fontawesome';
    top: 0;
    left: 0;
}




.head-txt
{ 
   font-size:40px;
   font-weight: 600;
   line-height: 60px;
}
.collapse-arrow
{  
   
   position: relative;
   /* padding: 50px; */
   text-align: center;
}
/* .collapse-arrow .btn
{
  margin-left: 50%;
  margin-top:45px;

} */
.usecase-page .owl-carousel .owl-item img
{
   width: 25%;
   margin-left: 40%;
}
.usecase-content-section .card-title
{
     font-size: 30px;
     font-weight: 600;
     margin: 30px 0;
}




/* .usecase-content-section .card
{
   padding: 30px;
} */
.usecase-content-section .owl-carousel .owl-nav button.owl-prev, 
.usecase-content-section .owl-carousel .owl-nav button.owl-next, 
.usecase-content-section .owl-carousel button.owl-dot
{
   font-size: 30px;
}
.usecase-content-section .owl-nav
{
  position: absolute;
    top: 30%;
}
/* .usecase-content-section .card
{
  border: 0px solid #AFCD82 !important;
} */
/*** elastic carsoule styles ***/
/* .rec-pagination
{
   display: none !important;
}
.list-home li
{
  margin-top: 15px;
  position: relative;
  padding-left: 25px;
  text-align: center;
  line-height: 30px;
  list-style: none;
  margin-left: -3em;
  text-align: center;
  font-weight: 500;
   padding: 10px 35px;;
}
 .systematrix-layout .rec-arrow
{
   color:#AFCD82 !important;
   position: relative;
   top:-20%;
   font-size: 40px !important;
}

.rec-carousel
{
   height: 700px !important;
} 
.gEIJqc, .fptlcG
{
  background: none !important;
  color: #00C9F7 !important;
  box-shadow: none !important;
}
.gEIJqc:hover:enabled, .gEIJqc:focus:enabled
{
  box-shadow: none !important;
} 
.systematrix-layout .rec-item-wrapper
{
   width: 350px !important;
   
    border: 1px solid #AFCD82;
   margin: 25px;
   border-radius: 10px; 
}
.rec-item-wrapper .card-body
{
  padding: 40px 18px;
}  */

/*** End elastic slider ***/
*:focus {
	outline: none !important;
}
.usecase-textslider
{
  background: url('./images/Union4.png') no-repeat center center;
  background-size: 100% 100%;
  height: 400px;
  margin-top: -35%;
}
.career-navtabs .nav-tabs .nav-link.active::before {
  content: "";
  display: block;
  width: 99%;
  border-bottom: 10px solid #00C9F7;
  top: 75px;
  position: relative;
  border-radius: 15px;
}
.career-navtabs .nav-tabs .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #00C9F7;
  left: 45%;
  bottom: -26%;
  z-index: 999;
}
.usecase-image
{
   margin-left:35%;
}
.success-msg
{
  color:#29A6A7;
  line-height: 55px;
    padding-bottom: 30px;
}
.check-icon
{
   color: #00C9F7;
   font-size:30px;
}
.flex-fill
{
  height: 550px;
}


a.no-border:hover
{
  text-decoration:none;
}
.ml-mr
{
    margin-right: 4.3%;
    margin-left:4.3%;
}

.collapse-rotate
{
  transform: rotate(-180deg);
}
.ui-element::after
{  
   content: url('./images/scroll_ui.png');

   z-index: 999;
   position: relative;
    top: -120px;
    right: 144px;
   
    float: left;
}
.systematrix-layout .carousel-control-prev-icon
{
  background-image:url('./images/slider-arrow-left.svg') !important;
}
.systematrix-layout .carousel-control-next-icon
{
  background-image:url('./images/slider-arrow-right.svg') !important;

}
.systematrix-layout .carousel-control-next-icon, .systematrix-layout .carousel-control-prev-icon
{
   width: 50px !important;
   height: 50px !important;;
}
.systematrix-layout .carousel-indicators .active
{
  background-color: #A8CE6D;
}
.navbar-toggler-icon
{
  content: url('./images/menuicon.svg');
  margin-right: 14px;
  width: 20px;
    height: 20px
}
.copyringts-info
{
   background-color: #4f4f4f;
   color:#fff;
}
.systematrix-layout 
 .MuiTypography-body1
{
  font-family: 'Raleway' !important;
  margin:30px 0;
  padding: 10px 0;
}

.pl-pr
{
   padding: 0 27px;
}
.mt-60
{
  margin-top:40px;
}
.link-head
{
   margin-left:15px;
}
.mlr-20
{
   margin-left: 10px;
   margin-right: 10px;
}
.ml-30
{
   margin-left: 30px;
}
.scrol-tabs .fit-img img
{
  border:4px solid #29A6A7;
   border-radius: 20px;
}
.pno
{
  padding-top:0px ;
}

.mt-15
{
    margin-top:25px;
}
/* @media (min-width: 576px) {  
  .systematrix-layout .carousel-caption.d-none.d-md-block
  {
          display: block !important;
  }
 
} */

.systematrix-layout  .carousel-control-next, .systematrix-layout  .carousel-control-prev
{
   top:-11%;
}
.p-40
{
   padding: 40px 80px;
}
.mt
 {
   margin-top: 20%;
   line-height: 50px;
  }
  .under-consrtution-head
  {
   color: #A8CE6D;
   font-size: 45px;
   margin-bottom: 30px;
   font-weight: 600;
  }
  .mt h3
  {
    margin-bottom: 30px;
  }
  .usecase-item
  {
      background: #fff;
  }
  .bottom-image
  {
    /* width: 100%; */
    background: url('./images/footer-bg.png') no-repeat center center;
    background-size: cover;
   height: 200px;
  }
  
  .systematrix-layout footer
  {
    background-color: #4f4f4f;
  }

  .copyright-info
  {  
     margin-left: 40%;
     margin-right: 20%;
  }
  .rec-item-wrapper .card-body
 {
  font-family: 'Raleway' !important;
 }
 /* .rec-item-wrapper
  {
    height: 550px;
     border-radius: 10px;
    border: 1px solid #AFCD82;
    margin: 5px;
  }
 
 
 .rec-item-wrapper .card-body .list-home
 {
    font-size:16px;
 } 
 .rec-item-wrapper .card-body .list-home li
 {
  padding: 10px 5px;
 }
 .systematrix-layout .imxhsh
  {
     color:#000;
     background-color: #fff;
     border:0px solid #000;
     border: 1px solid #AFCD82;
     margin: 25px;
     border-radius: 10px;
  }  */
 
  .address .mt-5
  {
     margin-top:30px !important;
  }
  .address h3
  {
     font-weight: 700;
  }

  /*** usecase elastic slider styles  ***/
  .systemarix-textcarsolue .card .card-title
{
   font-size:25px;
   font-family: 'Raleway' !important;
   font-weight: 600;
}

.list-home li
{
  /* margin-top: 15px;
  position: relative;
  padding-left: 25px;
  text-align: center; */
  line-height: 30px;
  list-style: none;
 margin-left: -3em; 
  text-align: center;
  font-weight: 500;
   padding: 10px 5px;
   font-size:16px;
   font-family: 'Raleway' !important;
}
.systemarix-textcarsolue .card .card-body
{
  padding: 20px 0 0 0;
 
}
.systemarix-textcarsolue .card
{
  min-height: 80vh !important;
  border: 1px solid #AFCD82 !important;
  background-color: #fff !important;
  color:#000 !important;
  margin: 20px !important;
}
.systemarix-textcarsolue .card .service-icon
{
  margin-left: 45%;
  margin-right: 25%;
}
.rec-item-wrapper
{
   min-width: 150px;
}
.systematrix-layout .rec-arrow
{
   color:#AFCD82 !important;
   position: relative;
   top:40%;
   font-size: 40px !important;
   cursor: pointer;
}
.gEIJqc, .fptlcG
{
  background: none !important;
  color: #00C9F7 !important;
  box-shadow: none !important;
}
.rec-pagination
 {
    display: none !important;
 }

 .testimonials-text .card-title
 {
     font-size:20px;
     font-weight: bold;
     padding: 15px 30px 0 30px;
 }
 .testimonial-content-section .card-deck .card
 {
    margin-bottom:20px !important;
 }
 .testimonial-user
 {
    list-style: none;
 }
 .testimonial-user li
 {
  margin-top: 10px;
  font-size: 12px;
  font-style:italic;
 }

 .testimonial-content-section .container-fluid
 {
  padding-left:0;
  padding-right:0;
 }

 /* .testimonial-content-section .card-deck
 {
     padding:0px 160px;
 } */
 .testimonials-text .btn-link
 {
    font-size:20px;
    font-weight: 600;
    text-decoration:none ;
 }  
 .testimonial-content-section .card-deck .card
 {
   padding-bottom: 10px;
 }


 /* .circle img
 {
     width: 50%;
 } */
 .team-image
 {
   width: 150px;
 }
 .team-member h2
 {
    font-size:20px;
    font-weight: 600;

 }
 .team-member h4
 {
   font-size: 16px;
 }

 .teams .card-subtitle
 {
   text-align: center;
   font-size:16px;
   font-weight: 500;
   padding-top:12px;
 }
 .teams .systemarix-textcarsolue .card
 {
  min-height: 55vh !important;
  border: 0px solid #AFCD82 !important;
  font-family: 'Raleway' !important;
 }
 .teams .systemarix-textcarsolue .card:hover
 {
  -webkit-box-shadow: 2px 1px 14px 0px #c7c7c7; 
  box-shadow: 2px 1px 14px 0px #c7c7c7;

 }
 .testimonial-content-section .systemarix-textcarsolue .card:hover
 {
  -webkit-box-shadow: 2px 1px 14px 0px #3754aa;
  box-shadow: 2px 1px 14px 0px #3754aa;
 }
 .teams 
 { 
   background-color: #f6f8ff;
   padding:100px 0;
 }
 /* .systematrix-layout .teams .rec-arrow
 {
   top:101%
   
 } */
 .systematrix-layout .teams .right-arrow
 {
  right: 50%;
 }
 .systematrix-layout .teams .left-arrow
 {
 left: 50%;
 } 
 .systematrix-layout .teams .rec-arrow
 {
  color: #000000 !important;
 }
 .modal-image
 {
    width: 150px;
 }
 /* .testimonial-modal .modal-body
 {
  height: 450px;
  overflow-x: auto;
 } */
 .systematrix-layout .modal-header
 {
   border:none !important;
 }
 .teams-desc
 {   
    font-size:16px;
    font-weight: 500;
 }
 .systematrix-layout .modal-content
 {
    border-radius: 18px;
    padding:10px 30px;
 }
 .teams .systemarix-textcarsolue .card
 {
  border-radius: 18px;
  font-family: 'Raleway' !important;
 }
 .team-m
 {
  padding-top: 50px;
  padding-left: 50px;
 }
 .team-member .team-m h2
 {
   font-weight: 600;
   font-size:24px;
 }
 .team-member .team-m h4
 {
   font-size:18px;
 }
 .mem-name
 {
   margin-top:30px;
 }
 .testimonial-modal .modal-body p
 {
     font-size: 16px;
     font-weight: 500;
     line-height: 1.5;
     margin-bottom: 30px;

 }
 .testimonial-user
 {
   font-style: italic;
   margin-left: -12px;
 }
 .list-1
 {
  font-size: 16px;
     font-weight: 500;
     line-height: 35px;
     margin-top:10px;
     margin-left:-20px;
     margin-bottom: 30px;
 }

 .testimonials-text .testimonial-user
 {
    font-style:normal;
 }
 .company
 {
   font-weight: 600 !important;
 }


 .card-title
 {
    margin-bottom: 0;
 }


 .testimonial-content-section .card-deck .card
 {
   margin-left:40px;
 }
 /* .teams .rec-carousel
{
  height: 400px !important;
} */

/* .testimonial-modal .card-title
{
  padding-left: 0 !important;
} */
.testimonial-modal .testimonial-user
{
  margin-left: -40px;
}
 .testimonial-modal.modal-lg
{
   max-width: 1000px;
} 
.testimonials-text:hover
{
  box-shadow: 2px 1px 14px 0px #c7c7c7;
}
.testimonial-modal .card-title
{

  font-size:20px;
  font-weight: bold;
  
}
.testimonials-text .card-title
{
  margin-bottom: 10px !important;
 
}

.usecase-content-section .nav-pills .nav-item:hover
{
  box-shadow: 2px 1px 14px 0px #c7c7c7;
}
.mtop-5
{
  margin-top:25px
}
.rectangle-box .collapse .fit-img img
{
   text-align: right;
   
}

.testimonial-content-section .systemarix-textcarsolue .card
{
  padding: 0px; 
  margin: 0px;
}
.testimonial-content-section .systemarix-textcarsolue .card .card-body
{
  padding: 0;
 
}

.navbar-expand-md .navbar-nav .nav-link
{
  padding-right: 5px;
    padding-left: 5px;
}
.navbar-collapse .navbar-nav
{
  padding-left: 20%;
}
/* .main-menu .nav-link
{
  margin:0 10px;
} */

.testimonial-content-section .systemarix-textcarsolue .card
{
  border:1px solid#f5f5f5 !important;
}
/* .button-arrow
{

} */

.testimonial-container
{
  background: #6ea0e6; /* Old browsers */
background: -moz-linear-gradient(top,  #6ea0e6 0%, #6ea0e6 53%, #617ebd 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #6ea0e6 0%,#6ea0e6 53%,#617ebd 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #6ea0e6 0%,#6ea0e6 53%,#617ebd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ea0e6', endColorstr='#617ebd',GradientType=0 ); /* IE6-9 */
}
.small-content
{
  margin-top:50px
}
.testimonial-container .rec-carousel
{
   height: 700px !important;
}
.systematrix-layout .testimonial-container .rec-arrow
 {
   top:30%;
   z-index: 999;
   
 }
 .systematrix-layout .testimonial-container .right-arrow
 {
  right: 30%;
 }
 .systematrix-layout .testimonial-container .left-arrow
 {
 left:30%;
 }
 /* .systematrix-layout .testimonial-container .rec-arrow:disabled
 {
  cursor: not-allowed !important;
  opacity: 0.5;
 } */

 .systematrix-layout .MuiSvgIcon-root
 {
   border:1px solid #fff;
   border-radius: 20px;
 }
 .systematrix-layout .MuiSvgIcon-fontSizeSmall
 {
   font-size:40px;
 }
 .over-view .p-20.m-20
 {
   padding-top: 0px;
   margin-top: -40px;
 }
 hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(55,84,170,.4);
  width: 90%;
}

.testimonial-container .systemarix-textcarsolue .card
{
  min-height: 85vh !important;
}
/* .testimonial-content-section .client-list 
{
    margin-left: 90px;
    margin-right: 50px;
} */
.client-list li
{
   list-style: none;
   display: inline; 
  
}

.client-list li span
{
  display: inline-block;
}
.client-list li img
{
  
  /* border:1px solid red; */
  height: 100px;
  /* padding: 5px 0; */
   
}
/* .client-list
{
   margin-left:40px;
} */
/* .overview-content-section .client-list 
{
    margin-left: 90px;
    margin-right: 40px;
} */
.systematrix-layout .usecase-content-section .rec-arrow
{
  top: 30%;
}
.readmore-link:hover
{
  -webkit-transition: all cubic-bezier(.37,.68,.27,.71) .4s;
    -o-transition: all cubic-bezier(.37,.68,.27,.71) .4s;
    transition: all cubic-bezier(.37,.68,.27,.71) .4s;
}
.systematrix-layout .our-client .head
{
  margin-top:60px;
  margin-left:0; 
}
 /* .systematrix-layout .testimonial-content-section .head
{
  margin-left:6%;
}  */
 .light-gray
{
  background: #f0f2f2 !important;
}

.client-list li span
{
   padding: 10px 35px !important;
}
.clients
{
   width:95%;
   margin-left: auto;
   margin-right: auto;
}