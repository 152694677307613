@media  screen and (max-width: 1366px) 
   
{ 
  .systematrix-layout .our-client .head
  {
    margin-left: 0%;
  }
  .systematrix-layout .testimonial-content-section .head
{
  margin-left:0%;
}
.client-list li span
{
  padding: 10px 35px !important;
}
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1200px) 
{
  .client-list li span
{
  display: inline;
  padding: 0 10px !important;
}

  .client-list li img
{
  width:30%;
  height: auto !important;
  padding: 2px 0;
  margin:0;
}
.client-list li
{
  padding: 0;
  margin:0;
}

  .testimonial-container .rec-carousel
  {
     height: 800px !important;
  }
  .testimonial-user li
  {
    font-size:12px;
  }
  .over-view .p-20.m-20 {
    padding-top: 0px;
    margin-top: 10px;
}
  /* .systematrix-layout .testimonial-container .rec-arrow
 {
   top:90%
   
 }
 .systematrix-layout .testimonial-container .right-arrow
 {
  right: 450px;
 }
 .systematrix-layout .testimonial-container .left-arrow
 {
 left: 450px;
 } */
  .systematrix-layout .testimonial-container .systemarix-textcarsolue .card
  {
    min-height: 50vh !important;
  }
  /* .systematrix-layout .teams .rec-arrow
  {
    top:110%;
  }
  .systematrix-layout .teams .right-arrow
  {
    right: 440px;
    width: 30px;
  }
  .systematrix-layout .teams .left-arrow
  {
    left:440px;
    width: 30px;
  } */
  .footer-logo {
    width: 60% !important;
    margin-left: -19% !important;
   }  
  .navbar-brand
  {
   text-align: left !important;
  }
  .navbar-collapse .navbar-nav
  {
    padding-left: 0 !important;
  }
  .modal
{
   top:15%;
}
  .testimonial-content-section .card-deck
  {
     /* padding:0 10px; */
     margin-right: 0px;
    margin-left: 0px;
  }
  .teams .systemarix-textcarsolue .card {
    min-height: 30vh !important;
  }
  .teams .rec-carousel
  {
    height: 400px !important;
  }
  .footer-section [class^="col"] , .usecase-page [class^="col"], .about-page .col, .page-top-header .col

  {
    padding-right: 0;
    padding-left:0;
  }
  .getstarted-section.row, .usecase-page .row, .over-view.row, .page-top-header .row, .overview .row
  {
      margin-left:0;
      margin-right: 0;
  }
  .getstarted-section .col-md-12, .usecase-page .container-fluid, .overview .container-fluid,  .page-top-header .container-fluid
  {
     padding-right: 0;
     padding-left:0;
  }
  .usecase-content-section .row,  .usecase-page .getstarted-section.row
  {
    margin-left:0;
    margin-right:0;
  }
  .contact-page .row
  {
    margin-right: 0;
   margin-left:0;
  }
  /* .page-top-header .content-headtext h2
{
  font-size:40px !important;
} */
.career-page .content-headtext h3
{
  font-size:30px !important;
}
  .no-margin
  {
   margin-right: 0;
   margin-left:0;
  }
  .list-circle
  { 
    margin-top:10%
  }
  .list-circle li
  {
    line-height: 35px;
  }
  .full-width
  {
      max-width: 100%;
      flex:0 0 100%;
  }
  .fbullet
  {
     margin-top:0;
  }
  .mbullet
  {
    margin-left:2px;
  }
  .footer-section [class^="col"] 
{
  padding-right: 0;
  padding-left:0;
}
.rec-carousel
   {
      height: 600px !important;
   }
   .usecase-textslider
   {
    margin-top: -18% !important;
   }
  .over-view::before
  {
    display: none;
  }
  .main-menu .nav-link
  {
    /* font-size:16px; */

    padding-right: 5px !important;
    padding-left: 5px !important;
    margin:0 5px !important;
    font-weight: 500;
   
  }
  .flex-fill {
    height: 650px;
}
.carousel-caption h3, .carousel-caption p
{
    font-size:30px;
}
.systematrix-layout .carousel-caption
{
  top:42%;
}
.systematrix-layout .carousel-control-next, .systematrix-layout .carousel-control-prev
{
  top:0%;
}
.head-blue
{
  font-size:25px;
}
.page-header-content h2
{ 
  font-size: 30px;
}
.page-top-header .page-header-content .content-headtext
{
  padding-top:25%;
}
.head
{
  font-size:35px;
}
.head-black
{
  font-size:20px;
}
.light-green, .orange
{
  font-size:30px;
  line-height: 40px;
}
.list-checkmark li
{
   line-height: 30px;
}
.contact-form .text-orange {
  font-size: 35px;
}
.address p
{
  font-size:22px;
}
.under-consrtution-head
{
  font-size:40px;
}
.mt h3
{
  font-size:22px;
}
.abt-para
{
  font-size:18px;
  line-height: 30px;
}
.rectangle-text
{
  font-size:25px;
}
.abt-list li
{
   font-size:20px;
}
.career-navtabs .nav-tabs .nav-link
{
  font-size:25px;
}
.career-navtabs .nav-tabs .nav-link.active::before
{
  top:67px;
}
.career-navtabs .card-title
{
  font-size:22px;
}
.job-title-head
{
  font-size:32px;
}
.job-des h3
{
   font-size: 28px;
}
.job-des h5
{
   font-size: 25px;
}
.job-des .jobdes-list li
{
  font-size:16px;
}
.btm-text
{
   font-size: 22px;
}
}
@media screen and (max-width: 991px)
{
  .systematrix-layout .main-menu .nav-link
  {
    font-size:14px;
    padding-right: 2px !important;
    padding-left: 2px !important;
    margin:0 5px !important;
    font-weight: 500;
    text-align: center;
  }
  .systematrix-layout  .head-black, .systematrix-layout  .head-blue
 {
     font-size:18px;
 }
 .abt-list li
{
  text-align: left;
}

.testimonial-content-section .card-deck
{
   padding-left:0;
   padding-right:0;
}
.systematrix-layout .testimonial-container .systemarix-textcarsolue .card
{
  min-height: 60vh !important;
}
.testimonial-container .rec-carousel
{
  height: 700px !important;
}
.testimonial-content-section .client-list 
{
    margin-left: 0px;
    margin-right: 0px;
}
}
@media screen and (max-width: 768px)
{
  .client-list li span
  {
    display: inline;
    padding: 0 10px !important;
  }
  .testimonial-content-section .client-list 
{
    margin-left: 0px;
    margin-right: 0px;
}
  .client-list li img
  {
    width:30%;
    height: auto !important;
    padding: 2px 0;
    margin:0;
  }
  .client-list li
  {
    padding: 0;
    margin:0;
  }
  
  .over-view .p-20.m-20 {
    padding-top: 0px;
    margin-top: 10px;
    }
  /* .systematrix-layout .testimonial-container .rec-arrow
 {
   top:92%
   
 }
 .systematrix-layout .testimonial-container .right-arrow
 {
  right: 320px;
 }
 .systematrix-layout .testimonial-container .left-arrow
 {
 left: 320px;
 } */
  .systematrix-layout .testimonial-container .systemarix-textcarsolue .card
  {
    min-height: 60vh !important;
  }
  /* .systematrix-layout .teams .rec-arrow
  {
    top:110%;
  }
  .systematrix-layout .teams .right-arrow
  {
    right: 320px;
    width: 30px;
  }
  .systematrix-layout .teams .left-arrow
  {
    left:320px;
    width: 30px;
  } */
  .footer-logo {
    width: 60% !important;
    margin-left: -19% !important;
   }  
  .navbar-brand
  {
   text-align: left !important;
  }
  .navbar-collapse .navbar-nav
  {
    padding-left: 0 !important;
  }
  .testimonial-content-section .card-deck
  {
     /* padding:0 10px; */
     margin-right: 0px;
    margin-left: 0px;
  }
  .testimonial-modal .card-title
  {
    font-size:18px !important;
    text-align: left;
    /* margin-left: -15px; */
  }
  .teams .rec-carousel
  {
     height: 350px !important;
  }
 .teams .systemarix-textcarsolue .card
  {
    min-height: 40vh !important;
  } 
  .contact-page .no-margin.row, .career-content-section .row
   {
      margin-left:0;
      margin-right:0;
  }
  .list-circle
  {
     margin-left:0%;
  }
  .list-circle li
  {
    margin-left: 5px;
  }
  .footer-section [class^="col"] , .usecase-page [class^="col"], .about-page .col, .page-top-header .col

  {
    padding-right: 0;
    padding-left:0;
  }
  .getstarted-section.row, .usecase-page .row, .over-view.row, .page-top-header .row, .overview .row
  {
      margin-left:0;
      margin-right: 0;
  }
  .getstarted-section .col-md-12, .usecase-page .container-fluid, .overview .container-fluid,  .page-top-header .container-fluid
  {
     padding-right: 0;
     padding-left:0;
  }
  .rec-carousel
  {
     height: 520px !important;
  }
  .usecase-textslider
  {
     margin-top:-25% !important;
  }
  .address h3
  {
    font-size:20px;
  }
  .usecase-content-section .nav-pills .nav-link
  { 
    padding: 20px 15px !important;
  }
  .usecase-content-section .nav-pills .nav-item .nav-link.active::after
  {
    left: 91%;
    margin-top: -37px;
  }
  .list-circle
  { 
    margin-top:5%
  }
  .full-width
  {
      max-width: 100%;
      flex:0 0 100%;
  }
  .fbullet
  {
     margin-top:0;
  }
  .mbullet
  {
    margin-left:2px;
  }
  .MuiTab-wrapper
{
    font-size: 20px;
}
.product-tab-head h3
{
   font-size: 20px;
}
  .systematrix-layout .main-menu .nav-link
  {
    font-size:12px !important;
    padding-right: 2px !important;
    padding-left: 2px !important;
    margin:0 5px !important;
    font-weight: 500;
    text-align: center;
  }
  .navbar-brand
  {
    text-align: center;
  }
  
  .systematrix-layout .carousel-caption h3, 
  .systematrix-layout .carousel-caption p
 {
    font-size:25px;
 }

 .systematrix-layout  .head
 {
     font-size:25px;
 }
 .getstarted-section h2
 {
     font-size: 16px;
 }
 .btn-pad {
  padding: 10px 15px;
  font-size: 14px;
}
.footer-section
{
   font-size:14px;
}
.footer-heading
{
   font-size: 18px;
   margin: 30px 0;
}
.systematrix-layout .carousel-caption h3, 
.systematrix-layout .carousel-caption p,.page-header-content h2

{
  font-size:18px !important;
}
/* .page-top-header .content-headtext h2
{
  font-size:30px !important;
} */
.career-page .content-headtext h3
{
  font-size:20px !important;
}
.usecase-content-section .card-title {
  font-size: 20px;
  font-weight: 600;
}
.list-home li
{ 
  font-size: 12px;
}
.usecase-content-section  .nav-pills .nav-link
{
   font-size: 18px;
}
.light-green
{
    font-size: 20px !important;
}
.orange
{
  font-size: 20px;
}
.list-checkmark li
{
  font-size: 16px;
  line-height: 35px;
}

.list-circle li
{
  font-size: 16px;
  line-height: 35px;
}
.career-navtabs .nav-tabs .nav-link
{
  font-size: 15px;
}
.career-navtabs .card-title
{
    font-size: 20px;
}
.career-navtabs .nav-tabs .nav-link.active::before
{
  top: 47px;
}
.job-title-head
{
  font-size:30px;
}
.job-des h3
{
   font-size: 20px;
}
.job-des h5
{
  font-size: 18px;
}
.jobdes-list
{
  font-size: 14px;
}
.btm-text,.mail-info
{
  font-size: 18px;
}
.about-page .head-black
{
   font-size: 16px;
}
.abt-para
{
  /* margin-left:0%; */
  font-size: 16px;
  line-height: 30px;
}
.rectangle-text
{
  font-size: 18px;
}
.abt-list li
{
  font-size: 16px;
}
.abt-list li::before
{
  font-size: 14px;
}
.mt-20
{
  margin-top: 40px;
}
.testimonials-text .card-text
{
  font-size:14px;
}
.testimonial-content-section .rectangle-box
{
  height: auto;
}
.fpara
{
   font-size: 14px;
   text-align: left;
}
.address p
{
  font-size: 16px;
}
.contact-form .text-orange
{
  font-size: 22px;
}
.over-view::before
{
  display: none;
}
.abt-list li
{
  text-align: left;
}
.systematrix-layout .carousel-caption {
  top: 40%;
}
.systematrix-layout .carousel-control-next, .systematrix-layout .carousel-control-prev
{
  top:-2%;
}
.page-top-header .page-header-content .content-headtext
{
  padding-top:25%;
}
.page-top-header .page-header-img
{
  height: 400px;
}

.under-consrtution-head
{
  font-size:30px;
}
.mt h3
{
   font-size:18px;
}

}

@media (max-width: 320px)
{
  /* .systematrix-layout .teams .left-arrow {
    left: 125px !important;;
    width: 30px;
}
.systematrix-layout .teams .right-arrow {
  right: 125px !important;;
  width: 30px;
}
  .systematrix-layout .testimonial-container .rec-arrow
 {
   top:80% !important;
   
 } */
 /* .systematrix-layout .testimonial-container .right-arrow
 {
  right:125px !important;
  width: 30px;
 }
 .systematrix-layout .testimonial-container .left-arrow
 {
 left: 125px !important;
 height: 30px;
 } */
  .teams .systemarix-textcarsolue .card
  {
    min-height: 75vh !important;
  }
  .career-page .content-headtext h3
  {
    font-size:14px !important;
  }
  .fit-img img
  {
    max-width: 100% !important;
    max-height: 100% !important;
    display: block;
  }
  .navbar-brand
  {
    margin-right: 0;
  }
  .contact-form
  {
    margin-left:0 !important;
    margin-right: 0 !important;
  }
  .address h3
   {
    font-size: 25px;
   }
   .systemarix-textcarsolue .rec-item-wrapper .card
   {
       margin: 0 !important;
   }
   .usecase-textslider
   {
      margin-top:-28% !important;
   }
   .career-page .content-headtext h3
   {
     width: 95% !important;
     margin-left:10px !important;
     margin-right: 10px !important;
   }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) 
{
  .rect-arrows .rec-arrow
  {
     width: 25px;
  }
  .abt-list li
  {
    line-height: 30px;
    padding-left: 30px;
  }
  .teams .systemarix-textcarsolue .card
  {
    min-height: 75vh !important;
  } 
  .fpara, .fbullet
  {
    margin-top:0px !important;
  }
  .systematrix-layout .carousel-caption.d-none.d-md-block
  {
          display: block !important;
  }
  .systematrix-layout .carousel-caption
  {
      top:45%;
      right: 0;
      left:0;

  }
  .systematrix-layout .carousel-caption h3, 
  .systematrix-layout .carousel-caption p,.page-header-content h2
  
 {
    font-size:14px !important;
 }
 .systematrix-layout .head-black, .systematrix-layout .head-blue
 {
     font-size:14px;
     line-height: 30px;
 }
 .systematrix-layout .head
 {
     font-size:20px;
 }
 
 .btn-pad {
  padding: 10px 15px;
  font-size: 14px;
}
.usecase-textslider
{
   margin-top:-60%;
}
.systemarix-textcarsolue .card
{
  margin:0;
}
.usecase-content-section .card-title
{
  font-size: 16px !important;
  margin:10px 0;
  padding:10px 0
}
.systematrix-layout .rec-arrow
{
  font-size:18px !important;
}
.footer-logo
{
  width: 30% !important;
  margin-left:0% !important;
}

.address p
{
   font-size: 16px;
   margin-left: 20px;
   text-align: center;
}
.contact-form
{
  margin-left: 40px;
  text-align: center;
  margin-right: 40px;
}

.contact-form .text-orange
{
   font-size: 22px;
}

.footer-section
{
   text-align: center;
}
.page-top-header .page-header-content .content-headtext
{
    padding-top: 39%;
}
.main-slider .slider-img
{
  min-height: 300px !important;
}
.product-page .page-header-img, .page-top-header .page-header-img
{
  height: 320px;
}
/* .main-slider
{
  min-height: 50vh !important;
} */

.scrol-tabs
{
  margin-left: 0;
}
.about-page .head-black
{
   font-size: 16px;
}
.abt-para
{
   margin-left:0%;
  font-size: 16px;
}
.rectangle-text
{
  font-size: 18px;
  padding: 0 10px;
}
.abt-list li
{
  font-size: 16px;
}
.abt-list li::before
{
  font-size: 10px;
}
.mt-20
{
  margin-top: 40px;
}
.testimonials-text .card-text
{
  font-size:14px;
}
.testimonial-content-section .rectangle-box
{
  height: auto;
}
.fpara
{
   font-size: 14px;
   text-align: center;
}
.main-menu .navbar
{
  background-color:#AFCD82;
}

.systematrix-layout .main-menu .nav-link
{
  font-size:16px !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
  margin:0 5px !important;
  font-weight: 500;
  text-align: center;
}
.navbar-brand
{
  text-align: left !important;
}
.content-headtext h3
{
   font-size: 25px;
}
.address-info p
{
  font-size: 14px;
    text-align: center;
}
.content-headtext h3 {
  font-size: 20px;
}
.over-view::before
{
  display: none;
}

.footer-heading
{
   margin:30px 0;
}

.MuiTab-wrapper
{
    font-size: 20px;
}
.product-tab-head h3
{
   font-size: 20px;
}
 /* .systematrix-layout .rec-item-wrapper
{
  width: 160px !important;
}  */
.list-home li
{
   padding: 10px 0;
}
.usecase-textslider
{
  background-size: cover;
    /* margin-top: -66%; */
}
/* .systematrix-layout .rec-item-wrapper
{
  margin: 10px 5px;
} */
.abt-para
{
  font-size: 12px;
}
.systematrix-layout
{
  text-align: center;
}
.abt-list li
{
  text-align: left;
}
.systematrix-layout .carousel-control-next-icon, .systematrix-layout .carousel-control-prev-icon
{
    width: 30px;
    height: 30px;
}

.systematrix-layout .carousel-control-next, .systematrix-layout .carousel-control-prev
{
    top:10%;
    width: 8%;
}
.border-purple
{
  border:2px solid #7B54E2;
}
/* .fit-img img
{
  max-width: 100%;
    height: auto;
} */
.getstarted-section
{
  background-size: cover;
}

.footer-heading::after
{
    width: 100%;
}

.img-fluid
{
   width: 380px !important;
}
.productsuite-section .m-20
{
   margin: 0 20px;
   padding: 0;
}
.productsuite-section .head-black
{
  margin-bottom:40px;
}
.overview-content-section .getstarted-section::before
{
  display: none;
}
.arrow-slider
{
  width: 25px;
  margin-top: 20px;
}
.scrol-tabs .fit-img img
{ 
  border: 2px solid #29A6A7;
}
.mp-30
{
  padding:40px 0;
}
.abt-list
{
   margin:0;
}
.map-style {
  border: 0;
  width: 80%;
  height: 500px;
}
.text-field.form-control
{
  font-size: 14px;
  height: 40px;
}

.under-consrtution-head
{
  font-size:30px;
}
.mt h3
{
  font-size:16px;
}
.mt p
{
   font-size:12px;
}
.ml-mr
{
   margin-left: 0px;
   margin-right: 0px;
}

.career-navtabs .nav-tabs .nav-item .nav-link.active::after
{
  content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #00C9F7;
    left: 45%;
    bottom: -19%;
    z-index: 999
}
.career-content-section h3.head
{
     font-size:20px;
}
.career-navtabs .nav-tabs .nav-link
{
  padding: 10px 0;
  font-size:14px;
}
.career-navtabs .nav-tabs .nav-link.active::before
{
  border-bottom: 4px solid #00C9F7;
  top:34px;
  width: 98%;
    left: 1px;
}

.career-navtabs .nav-tabs
{
  padding: 30px 5px;
}
.career-navtabs .card-title, .readmore-link
{
  font-size:14px;
}
.career-navtabs .card-text
{
  font-size:14px;

}
.career-page .content-headtext h2
{
   font-size:20px !important;
}
.career-page .content-headtext h3
{
    font-size:14px !important;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 25px;
    width: 85%;
}
.back-btn-text
{
   font-size: 14px;
}
.back-btn img
{
   width: 10px;
}
.job-title-head
{
  font-size: 22px;
}
.job-des .jobdes-list li
{
  font-size: 12px;
}
.btm-text, .mail-info
{
  font-size:14px;
}
.job-des .jobdes-list, .info.btm-text
{
  margin-left: 15px;
  margin-right: 15px;
}
.m-20
{
  margin:40px 20px;
  padding: 0;
}
.usecase-content-section .nav-pills .nav-link
{
  font-size:12px;
  padding: 30px 15px;
}
.ml-30
{
  margin-left: 0;
}
.flex-40 
{
   flex-basis: 40%;
   padding-left: 0 !important;
   padding-right: 0 !important;
}
.flex-60
{
  flex-basis: 50%;
  padding-left: 0 !important;
   padding-right: 0 !important;
}
.orange, .light-green
{
  font-size:15px !important;
  line-height: 30px;
  padding-left:5px;
}
.list-checkmark li
{
  font-size: 14px;
}
.product-tab-head
{
  padding: 10px;
}
.scrol-tabs .fit-img img
{
  width: 340px !important;
  max-width: 340px;
}
.systematrix-layout .MuiTab-textColorPrimary
{
   width: 180px;
}
.MuiTab-wrapper
{
  font-size:15px;
}
.systematrix-layout .MuiTab-textColorPrimary
{
  margin:0 15px;
}
.fit-img img
{
  width: 320px !important;
  /* max-width: 320px; */
}
/* .footer-section {

  background: url('./images/footer-mobile.png') no-repeat center center;
  background-size: cover;
  padding: 30px 40px 150px 40px;
  color: #fff;

} */
.usecase-tabcontent .fit-img img
{
  max-width: 100% !important;
    max-height: 100%;
    display: block;
}
.list-circle li
{
  font-size:14px;
}
.bottom-image
  {
    /* width: 100%; */
    background: url('./images/footer-bg.png') no-repeat center center;
   background-size: cover;
   
  }
  .list-circle
  {
     margin-top:0;
  }
  .usecase-content-section .rectangle-box {
    background: url('./images/rectangle-mobile.png') no-repeat;
    background-size: 100% 100%;
}
.rectangle-box .head-txt
{
  margin-top: -90px;
  line-height: 30px;
}
.collapse-arrow .btn
{
   margin-left:0;
}
.list-checkmark li::before
{
    content: url(./images/check-mark-button-mobile.svg);
    position: absolute;
    top: 6px;
    left: -21px;
  
}
.aml img
{
  width: 400px !important;
}
.logo-sys
{
  width: 20%;
}
.fpara
{
  padding: 0;
}
.main-menu .nav-link.active
{
   font-weight: 700;
}
.product-tab-head h3
{
  text-align: left;
    margin-left: 29px;
}
.copyright-info
  {  
    margin-left: 6%;
    margin-right: 4%;
  }
 .systematrix-layout .row
  {
      margin-left:0;
      margin-right: 0;
  } 
  .systematrix-layout .col,   .systematrix-layout .container-fluid, .career-navtabs .col-md-12
  {
    padding-left: 0;
   padding-right: 0;
  }
  /* .about-page .col, .about-page .career-content-section .container-fluid, .page-top-header .col, .page-top-header .container-fluid
  {
   padding-left: 0;
   padding-right: 0;
  }
  .systematrix-layout .col, .systematrix-layout .container-fluid
  {
    padding-left: 0;
   padding-right: 0;
  } */
  .usecase-image
  {
    width:25%;
    margin-left:-3%;
  }
  .usecase-tabcontent
  {
    margin-left: 20px;
  }
  .usecase-tabcontent .row
  {
    margin-left: -15px;
    margin-right:-15px;

  }
  .list-checkmark li {
    line-height: 25px;
    margin-top: 20px;
}
.address h3
 {
    margin: 30px 0;
 }
 .address .mt-5
 {
   margin-top: 0 !important;
 }
 .rec-item-wrapper .card-body .list-home li
 {
   padding: 5px 5px;
 }
 .usecase-textslider
 {
  background: url('./images/usecase-rectangle-mobile.png') no-repeat center center;
  height: 200px;
  margin-top: -50%;
 }

 .rec-carousel
 {
    height: 450px !important;
 }
 .rec-pagination
 {
    display: none !important;
 }
 .systemarix-textcarsolue .card
 {
    padding: 0;
 }
 .usecase-page .content-headtext h2, .contact-page .content-headtext h2, .testimonial-page .content-headtext h2
 {
   font-size:20px !important;
 }
 .usecase-page .col-md-12
 {
   padding-right: 0;
   padding-left:0;
 }
 .usecase-content-section .nav-pills .nav-item .nav-link.active::after
 {
  margin-top: -25px;
}
.usecase-content-section .nav-pills .nav-link
{
  padding:10px !important;
}
.usecase-content-section .nav-tabs
{
  margin-left: 5px !important;
}
.address h3
{
  font-size:18px;
}
.list-circle 
{
  margin-left:0px;
}
.list-circle li
{
  line-height: 30px;
}
.usecase-content-section .nav-pills .nav-item .nav-link.active::after
  {
    left: 95%;
    
  }
  .systemarix-textcarsolue .card .card-body
  {
   padding-top:10px;
  }
  .systemarix-textcarsolue .card{
      padding:0;
  }
  .list-circle
  {
     text-align: left;
  }
  .productsuite-section  .fit-img
  {
    margin-top:20px;
  }
  /* .testimonial-content-section .card-deck
  {
    padding: 0px 30px;
} */
.teams .systemarix-textcarsolue .card
{
  font-size:20px;
}
.teams-desc
{
  font-size: 13px;
  line-height: 20px;
} 
.team-member .team-m h4
{
  font-size:14px !important;
}
.team-member .team-m h2
{
  font-size:18px !important;
}
.mem-name
{
   font-size:18px !important;
}
.team-m
{
  padding-left:0px;
  padding-top: 20px;
}
.systematrix-layout .teams-modal .modal-content
{
  padding:0 !important;
}
.mn-padding
{
   padding-left: 0;
   padding-right: 0;
}
.systematrix-layout .teams .rec-arrow-right
{
  right:39%;
}
.systematrix-layout .teams .rec-arrow-left
{
  left:39%;
}
.teams .rec-carousel
{
  height: 350px !important;
}
.testimonial-modal .modal-body p, .list-1
{
   font-size:14px;
}
.list-1
{
   text-align: left !important;
   margin-left: -15px;
}

.testimonial-modal .modal-body p, .list-1 li
{
  line-height: 30px;
}
.testimonials-text .card-title
{
  font-size:14px;
}
.testimonial-modal .card-title
{
  font-size:18px !important;
  text-align: left;
  /* margin-left: -15px; */
}

.testimonial-user
{
    font-size:12px;
    text-align: left !important;
}
.testimonial-user li
{
  font-size:10px;
  margin-top:5px;
}
.testimonials-text .btn-link
{
   font-size:14px;
}
.mn-padding img
{
   text-align:center;
}
.testimonial-modal.modal-lg
{
  max-width: 500px;
}

.testimonial-content-section .card
{
  text-align:left;
}
.testimonial-content-section .rectangle-box
{
  background:url('./images/Rectangle-blue.png') no-repeat;
  height: auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6ea0e6+0,6ea0e6+53,617ebd+100 */
background: #6ea0e6; /* Old browsers */
background: -moz-linear-gradient(top,  #6ea0e6 0%, #6ea0e6 53%, #617ebd 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #6ea0e6 0%,#6ea0e6 53%,#617ebd 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #6ea0e6 0%,#6ea0e6 53%,#617ebd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ea0e6', endColorstr='#617ebd',GradientType=0 ); /* IE6-9 */

}
.testimonials-text .card-body::before
{
  content: url('./images/quote-mobile.svg');
}
.mobile-img
{
  width: 30px;
}
/* .testimonials-text .card-text
{
  padding:6px;
} */
.testimonial-content-section .card-deck .card
{
  margin-left:0;
}
.testimonials-text .card-text
{
  line-height: 30px;
}
.teams-modal .modal-title
{
   margin-left:60px;
}
.mtop
{
  margin-top:10px;
}
.teams-modal .modal-body
{
  padding-top: 0;
}
.modal
{
   top:15%;
}
.systematrix-layout .carousel-indicators li
{
  width:8px;
  height: 8px;
}
.product-info .m-20, .productsuite-section .m-20
{
  padding-top: 10px;
  margin-top:0px;
}
.product-info .head
{
   margin-top:-40px;
}
.systematrix-layout .head
{
  margin-bottom: 5px;
}
.flagship-section .p-80 {
  padding: 200px 0 100px 0;
}
.job-des .jobdes-list li
{
   text-align: left;
   line-height: 25px;
}
.job-des h3
{
  padding: 5px 0;
}
.job-des h5
{
  padding-top:5px;
  padding-bottom: 10px;
}
.job-des
{
  padding-top:15px;
}
.btm-text, .info.btm-text
{
  line-height: 30px;
}
.job-details
{
  padding-bottom: 0;
  padding-top: 30px;
}
.about-page .m-20
{
   margin:30px 20px 10px 20px !important;
}
.rectangle-text
{
  line-height: 40px;
}
.mb-20
{
  padding-bottom: 30px;
}
.about-page .bg-light
{
  padding-top:5px;
}


/**menu changes **/
.navbar-collapse .navbar-nav
{
  padding-left: 0 !important;
}
.over-view .p-20.m-20
{
  margin-top:10px;
}
/***320 **/
/* .systematrix-layout .teams .left-arrow {
  left: 145px;
  width: 30px;
}

.systematrix-layout .teams .rec-arrow {
  top: 105%;
}
.systematrix-layout .teams .right-arrow
{
  right: 145px;
  width: 30px;
} */
/* .systematrix-layout .testimonial-container .rec-arrow
 {
   top:90%
   
 }
 .systematrix-layout .testimonial-container .right-arrow
 {
  right:135px;
  width: 30px;
 }
 .systematrix-layout .testimonial-container .left-arrow
 {
 left: 135px;
 height: 30px;
 } */
 .systematrix-layout .testimonial-container .systemarix-textcarsolue .card
 {
    font-size: 10px;
 }
 .testimonials-text .card-text
 {
   padding: 25px 20px 0px 20px!important;
 }
 .systematrix-layout .testimonial-container .systemarix-textcarsolue .card
 {
  min-height: 55vh !important;
 }
 .testimonial-container .rec-carousel
 {
   height: 550px !important;
 }
 .mobile-nopadding
{
  padding-left: 0;
  padding-right:0;
}
.mobile-nopadding .product-tab-img
{
   padding: 0 !important;
}
.MuiBox-root-6
{
  padding: 0 !important;
}
.testimonial-container .systemarix-textcarsolue .card
{
  min-height: 50vh !important;
}
.client-list li img
{
  width:30%;
  height: auto !important;
  padding: 2px 0;
  margin:0;
}
.client-list li
{
  padding: 0;
  margin:0;
}
.testimonial-content-section .client-list 
{
    margin-left: 0px;
    margin-right: 40px;
}
.overview-content-section .client-list 
{
    margin-left: 0px;
    margin-right: 40px;
}
.systematrix-layout .our-client .head
{
  margin-top:40px;
  margin-bottom: 10px;
}
.client-list li span
{
  display: inline;
  padding: 0 !important;
}
.systematrix-layout .testimonial-content-section .head, .systematrix-layout .our-client .head
{
  margin-bottom: 15px !important;
}
 }